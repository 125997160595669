import { Controller } from "@hotwired/stimulus";

export default class ClassroomUrlsController extends Controller<HTMLElement> {
  urlInputTarget: HTMLInputElement;

  static targets = ["urlInput"];

  assign(e: Event) {
    e.preventDefault();
    if (e.target instanceof HTMLElement) {
      this.urlInputTarget.value = e.target.dataset.url;
    }
  }
}
